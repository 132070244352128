// eslint-disable-next-line import/prefer-default-export

// HAN
export const defaultDeparture = {
  id: 52828,
  icao: 'VVNB',
  iata: 'HAN',
  name: 'Noi Bai International Airport',
  city: 'Hanoi',
  state: 'Ha-Nội',
  country: 'VN',
  elevation: 39,
  lat: 21.22119,
  lon: 105.80718,
  tz: 'Asia/Bangkok',
  displayName: 'Hanoi (HAN), Vietnam',
  countryName: 'Vietnam',
  cityName: 'Hanoi',
  popularity: 16818,
}

// SGN
export const defaultArrival = {
  id: 48742,
  icao: 'VVTS',
  iata: 'SGN',
  name: 'Tan Son Nhat International Airport',
  city: 'Ho Chi Minh City',
  state: 'Ho-Chi-Minh-City',
  country: 'VN',
  elevation: 33,
  lat: 10.8188,
  lon: 106.65186,
  tz: 'Asia/Ho_Chi_Minh',
  displayName: 'Ho Chi Minh City (SGN), Vietnam',
  countryName: 'Vietnam',
  cityName: 'Ho Chi Minh City',
  popularity: 15959,
}

export const SORT_ITINERARIES_TYPE = {
  BY_CHEAPEST_FARE: 'CHEAPEST_FARE',
  BY_EXPENSIVEST_FARE: 'EXPENSIVEST_FARE',
  BY_EARLIEST_DEPARTURE: 'EARLIEST_DEPARTURE',
  BY_LATEST_DEPARTURE: 'LATEST_DEPARTURE',
  BY_EARLIEST_ARRIVAL: 'EARLIEST_ARRIVAL',
  BY_LATEST_ARRIVAL: 'LATEST_ARRIVAL',
  BY_SHORTEST_DURATION: 'SHORTEST_DURATION',
  BY_LONGEST_DURATION: 'LONGEST_DURATION',
  BY_AIRLINE: 'AIRLINE',
}

export const BUSINESS_FILTER_REGEX = /Business|First/
export const SKYBOSS_FILTER_REGEX = /SkyBoss/
export const BUSINESS_SKYBOSS_FILTER_REGEX = /Business|SkyBoss|First/

export const SOURCE_AK_ECONOMY_FARE = ['X', 'K', 'E', 'O', 'W', 'Z', 'I', 'A', 'V', 'P', 'L', 'U', 'T', 'Q', 'M', 'Y']
