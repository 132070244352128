<template>
  <div>
    <!-- <div>
      <Search
        :default-search-data="searchData"
        @handleSearch="handleSearch"
      />
    </div> -->

    <div
      id="calendar-cheapest-month"
      class="app-calendar overflow-hidden d-flex justify-content-center"
    >
      <b-row class="mx-0 w-100 mt-1">
        <b-col
          cols="12"
          lg="9"
          md="8"
          class="mb-2 px-0"
        >
          <IAmOverlay :loading="isEmpty(calendarOptionCustom)">
            <div
              v-for="(calendar, calendarIndex) in calendarOptionCustom"
              :key="calendarIndex"
            >
              <b-row
                class="w-100 mx-0"
                no-gutters
              >
                <b-col cols="12">
                  <b-card
                    class="border"
                    header-bg-variant="light-info"
                    header-class="py-1"
                    no-body
                  >
                    <template #header>
                      <span class="font-weight-bolder d-flex-center text-dark">
                        {{ $t('reservation.itinerary') }}:
                        <h3 class="font-weight-bolder m-0 ml-1">
                          {{ calendarIndex === 0
                            ? `${searchFlightArray[0].startPoint} - ${searchFlightArray[0].endPoint}`
                            : `${searchFlightArray[1].startPoint} - ${searchFlightArray[1].endPoint}`
                          }}
                        </h3>
                      </span>
                    </template>

                    <div class="card-body pb-0">
                      <full-calendar
                        ref="refCalendar"
                        class="full-calendar"
                        :options="calendar"
                      >
                        <!-- <template #dayCellContent="arg">
                          <div class="text-center">
                            <span class="font-medium-2'">
                              {{ arg.dayNumberText }}
                            </span>
                            {{ arg.date ? convertISODateTime(arg.date,'UTC').dateFilter : 'zz' }}
                          </div>
                        </template> -->

                        <template v-slot:eventContent="arg">
                          <div class="d-flex-center justify-content-around font-weight-bolder text-body-3">
                            <span>
                              <span class="d-none d-xl-block">
                                {{ arg.event.extendedProps.airline }}
                              </span>
                              <span class="d-block d-xl-none font-small-3">
                                {{ currencyVNFormatter(arg.event.title) }}
                              </span>
                            </span>

                            <span class="d-none d-xl-block">
                              {{ arg.event.title }}
                            </span>
                          </div>
                        </template>

                      </full-calendar>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
              <hr
                v-if="calendarIndex !== calendarOptionCustom.length - 1"
                class="p-50 text-danger"
              >
            </div>
          </IAmOverlay>
        </b-col>

        <b-col
          cols="12"
          lg="3"
          md="4"
          class="mb-2 pr-0"
        >
          <b-card
            header-bg-variant="light-info"
            header-class="p-1"
            body-class="px-25"
          >
            <template #header>
              <h3 class="text-airline font-weight-bolder mb-0">
                {{ $t('flight.cheapestSearch') }}:
              </h3>
            </template>
            <div
              style="min-height: 300px"
              class="mt-1"
            >
              <b-card
                v-for="(dateSelect, index) of dateShow"
                :key="index"
                class="border"
                header-bg-variant="light-info"
                header-class="py-1"
                body-class="px-25"
              >
                <template #header>
                  <b>
                    {{ index === 0 ? `${$t('flight.selectDepartDay')}` : `${$t('flight.selectReturnDay')}` }}
                  </b>
                </template>

                <div class="mt-1">
                  <p
                    class="my-75 border rounded-lg px-1 py-50"
                    :class="!dateSelect.date ? 'font-small-3' : 'font-weight-bolder'"
                  >
                    {{ dateSelect.date ? convertISODateTime(dateSelect.date).date : `${$t('flight.placeholderSelect')}` }}
                  </p>

                  <!-- <b-button
                        variant="gradient-danger"
                        class="btn-icon rounded-circle"
                        @click="handle"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button> -->
                </div>
              </b-card>
              <b-card
                class="border"
                header-bg-variant="light-info"
                header-class="py-1"
                body-class="px-25"
              >
                <template #header>
                  <b>{{ $t('flight.selectAirline') }}: </b>
                </template>
                <div class="mt-1 pl-50 border rounded-lg">
                  <SearchSourceSelect
                    :airlines.sync="airlines"
                    :is-search-class-booking="false"
                    :is-search-monthly-cheapest-fare="false"
                  />
                </div>
              </b-card>
            </div>
            <div class="text-center mt-2">
              <b-button
                v-ripple.400
                variant="gradient"
                pill
                :disabled="selectedDate.some(item => item.date === null)"
                @click="onSubmit"
              >
                <div class="d-flex align-items-center">
                  <img
                    src="@icons/search.svg"
                    alt="Search"
                    class="mr-25 ico-bold"
                  >
                  {{ $t('flight.searchFlight') }}
                </div>
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import {
  onUnmounted,
  onBeforeMount,
  ref,
  reactive,
  computed,
} from '@vue/composition-api'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import isEmpty from 'lodash/isEmpty'
import Ripple from 'vue-ripple-directive'

import IAmOverlay from '@/components/IAmOverlay.vue'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import {
  convertISODateTime,
  formatCurrency,
  getArrayDatesBetween,
  currencyVNFormatter,
} from '@core/utils/filter'

import SearchSourceSelect from '@flightv2/search/components/SearchSourceSelect.vue'
import useBookingHandle from '@flightv2/useBookingHandle'
import storeModule from '@flightv2/bookingStoreModule'

export default {
  components: {
    BCard,
    BButton,
    BCol,
    BRow,

    IAmOverlay,
    FullCalendar,
    SearchSourceSelect,
    // Search,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  setup() {
    const { router } = useRouter()
    const {
      FLIGHT_APP_STORE_MODULE_NAME,
      fetchCheapestFare,
    } = useBookingHandle()

    if (!store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
      store.registerModule(FLIGHT_APP_STORE_MODULE_NAME, storeModule)
    }

    onUnmounted(() => {
      if (store.hasModule(FLIGHT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FLIGHT_APP_STORE_MODULE_NAME)
      }
    })

    const refCalendar = ref(null)
    const selectedDate = ref([])
    const cheapestFare = ref()
    const tempCheapestFare = ref([])
    const calendarOptionCustom = reactive([])
    const airlines = ref([])
    const dateShow = computed(() => selectedDate.value)

    // const highlightedDays = ref(['2023-11-24'])

    // const handleDateClick = (date, index) => {
    //   const getDate = selectedDate.value.find(item => item.index === index)
    //   const dayElement = date.dayEl
    //   dayElement.classList.add('highlight')
    //   if (getDate) {
    //     const dateStr = date.dateStr
    //     // Set date to selectedDate
    //     getDate.date = dateStr
    //     // highlightedDays.value.push(dateStr)
    //   }
    // }

    function handleDayClick(date, index) {
      const getDate = selectedDate.value.find(item => item.index === index)
      if (getDate) {
        const dateStr = date.dateStr
        getDate.date = dateStr
      }

      // Reset highlight on all days
      // document.querySelectorAll('.highlight').forEach(el => {
      //   el.classList.remove('highlight')
      // })

      // Add highlight to the clicked day
      // const dayElement = date.dayEl
      // dayElement.classList.add('highlight')
    }

    const handleEventClick = (eventClickInfo, index) => {
      const getDate = selectedDate.value.find(item => item.index === index)
      if (getDate) {
        getDate.date = eventClickInfo.event.startStr
      }
    }

    function getMonthStartEndDates(date) {
      const currentDate = new Date(date)
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1

      const startDate = `${year}-${month.toString().padStart(2, '0')}-01`
      const endDate = `${year}-${month.toString().padStart(2, '0')}-${new Date(year, month, 0).getDate()}`

      return {
        startDate,
        endDate,
      }
    }

    function resolveColor(airline) {
      if (['VJ', 'VZ'].includes(airline)) return '#EB2A2B'
      if (airline === 'VU') return '#FEC813'
      if (airline === 'VN') return '#176887'
      if (airline === 'QH') return '#65A855'
      return '#000'
    }

    const query = localStorage.getItem('searchFlight')
    const searchData = ref({ ...JSON.parse(query) })
    store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSearchFlight`, searchData.value)
    const searchFlightArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()

    // const handleEventMouseEnter = event => {
    //   const eventEl = event.target
    //   eventEl.classList.add('highlight')
    // }

    // const handleEventMouseLeave = event => {
    //   const eventEl = event.target
    //   eventEl.classList.remove('highlight')
    // }

    async function submitSearch() {
      cheapestFare.value = null
      tempCheapestFare.value = []

      const values = await Promise.all(
        searchFlightArray.map((item, index) => {
          const payload = {
            departure: item.startPoint,
            arrival: item.endPoint,
            startDate: getMonthStartEndDates(item.departDate).startDate,
            endDate: getMonthStartEndDates(item.departDate).endDate,
          }

          tempCheapestFare.value[index] = getArrayDatesBetween(
            payload.startDate,
            payload.endDate,
          )

          return fetchCheapestFare(payload)
        }),
      )

      const valuesDate = values.map(val => val.data)

      tempCheapestFare.value.forEach((item, index) => {
        const valArray = valuesDate[index]
        item.forEach((itemDate, indexDate) => {
          const r = valArray.find(v => v.date === itemDate.date)
          if (r) tempCheapestFare.value[index][indexDate] = r
        })
      })

      if (tempCheapestFare.value.length) {
        const result = tempCheapestFare.value
          .map(el => el
            .filter(item => item.cheapestFare)
            .flatMap(item => item.cheapestFare
              .filter(fare => ['VN', 'VJ', 'QH', 'VU'].includes(fare.airline))
              .map(fare => {
                const {
                  id, date, airline, netFare,
                } = fare

                return {
                  id,
                  date,
                  airline,
                  title: `${formatCurrency(netFare)}`,
                  allDay: true,
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  textColor: resolveColor(airline),
                  // editable: false,
                }
              })))

        result.forEach((item, index) => {
          selectedDate.value.push({
            index,
            date: null,
          })

          calendarOptionCustom.push(
            {
              locale: 'vi',
              plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
              initialView: 'dayGridMonth',
              initialDate: getMonthStartEndDates(result[index][0]?.date).startDate,
              headerToolbar: {
                start: 'sidebarToggle, title', // prev,next,
                end: '',
              },
              titleFormat: { year: 'numeric', month: 'long' },
              aspectRatio: 0.9,
              validRange: {
                start: new Date().toISOString().split('T')[0], // Ngày hiện tại
              },
              dateClick: val => handleDayClick(val, index),
              // dayCellContent: arg => handleDayCellContent(arg, index),

              eventClick: val => handleEventClick(val, index),
              eventTextColor(arg) {
                return arg.event.borderColor
              },
              // eventBorderColor(arg) {
              //   return arg.event.borderColor
              // },
              eventBackgroundColor(arg) {
                return arg.event.backgroundColor
              },
              // eventDidMount: info => {
              //   const eventEl = info.el
              //   eventEl.addEventListener('mouseenter', handleEventMouseEnter)
              //   eventEl.addEventListener('mouseleave', handleEventMouseLeave)
              // },
              eventOrder: item => item.netFare,
              events: result[index],
            },
          )
        })
      }
    }

    onBeforeMount(async () => {
      submitSearch()
    })

    function handleSearch(data) {
      calendarOptionCustom.value = []
      searchData.value = data
      store.dispatch(`${FLIGHT_APP_STORE_MODULE_NAME}/setSearchFlight`, data)
      submitSearch()
    }

    function onSubmit() {
      const data = JSON.parse(localStorage.getItem('searchFlight'))
      data.flights[0].departDate = selectedDate.value[0].date

      if (selectedDate.value.length > 1) {
        data.flights[0].returnDate = selectedDate.value[1].date
      }

      data.airlines = airlines.value

      localStorage.setItem('searchFlight', JSON.stringify(data))
      router.push({
        name: 'apps-flightsV2-result',
      })
    }

    return {
      refCalendar,
      convertISODateTime,
      currencyVNFormatter,
      searchData,
      cheapestFare,
      selectedDate,
      searchFlightArray,
      onSubmit,
      isEmpty,
      handleSearch,
      calendarOptionCustom,
      // handleDateClick,
      handleEventClick,
      tempCheapestFare,
      dateShow,
      airlines,
    }
  },
}
</script>

<style lang="scss" scoped>
.title {
  color: black
}

::v-deep .fc-daygrid-event-harness {
  background-color: gray;
}

::v-deep .fc-daygrid-day-number {
  color: black !important;
}

::v-deep .fc-daygrid-day-top {
  justify-content: center !important;
  font-weight: 700 !important;
}

#calendar-cheapest-month ::v-deep {
  .fc-daygrid-day {
    &:hover {
      background-color: rgba(179, 214, 255, 0.3);
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.highlight {
  background-color: rgb(238, 236, 139) !important;
  // border: 1px solid #F0AC01 !important;
}

h2.fc-toolbar-title {
  text-transform: uppercase;
  font-weight: 900 !important;
  padding: 8px;
}

div.fc-header-toolbar.fc-toolbar {
  margin: 0px !important;
}

.fc .fc-toolbar {
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss" scoped>
.right-side-sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 50px;
  right: 0;
  max-height: calc(100vh - 50px);
}
</style>
